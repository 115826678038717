<template>
  <div class="animated fadeIn">
    <div v-if="loadingOrder">
      <loading-animation />
    </div>
    <div v-else-if="order">
      <h4 class="ml-md-0 ml-3 mb-3">Documento {{order.number}}</h4>

      <div class="row mx-md-n3 mx-0">
        <div class="col-md-6 col-12">
          <label>Item:</label>

          <model-list-select
            :list="items"
            option-value="id"
            option-text="label"
            v-model="item"
            placeholder="Digite para pesquisar..."
            :isDisabled="loadingTrack"
          />
        </div>
        <div class="col-md-6 col-12 mt-md-0 mt-3">
          <label>Direção da busca:</label>

          <b-form-radio-group v-model="direction" :disabled="loadingTrack">
            <b-form-radio name="directionOptions" value="ASC">
              Ascendente
            </b-form-radio>
            <b-form-radio name="directionOptions" value="DESC">
              Descendente
            </b-form-radio>
          </b-form-radio-group>

          <p class="text-muted mt-2">
            <span v-if="direction === 'ASC'">Busca os pedidos de destino dos lotes movimentados nesse pedido</span>
            <span v-if="direction === 'DESC'">Busca os pedidos de origem dos lotes movimentados nesse pedido</span>
          </p>
        </div>
      </div>

      <div class="row justify-content-between my-3">
        <div class="col-auto">
          <button-back v-if="!loadingTrack"></button-back>
        </div>
        <div class="col-auto">
          <b-button type="button" variant="primary" :disabled="loadingTrack || !item || !item.id" @click="searchHierarchy"
            class="mr-md-0 mr-3">
            Buscar
          </b-button>
        </div>
      </div>

      <div v-if="loadingTrack" class="row justify-content-center">
        <div class="col-auto">
          <loading-animation />
        </div>
      </div>

      <div v-if="!loadingTrack && searchButtonAtlLeastOncePressed && (!rowData || !rowData.length)" class="mb-3">
        <div class="row bg-light mx-0 py-3">
          <div class="col text-center">
            Nenhum histórico encontrado
          </div>
        </div>
      </div>

      <ag-grid-vue
        v-if="!loadingTrack && rowData && rowData.length"
        :columnDefs="columnDefs"
        :rowData="rowData"
        :treeData="true"
        :getDataPath="getDataPath"
        :modules="agGridModules"

        style="width: 100%; height: 500px;"
        class="ag-theme-balham"
        :animateRows="true"
        :groupDefaultExpanded="groupDefaultExpanded"
        :defaultColDef="defaultColDef"
        id="myGrid"
        :gridOptions="gridOptions"
        @grid-ready="onGridReady"
        :autoGroupColumnDef="autoGroupColumnDef"
        :allowContextMenuWithControlKey="true"
        :getContextMenuItems="getContextMenuItems"
      >
      </ag-grid-vue>

      <b-modal
        centered
        lazy
        :title="selectedOrder ? ('Informações do pedido ' + (selectedItem.number || selectedItem.documentNumber)) : ''"
        header-bg-variant="info"
        footer-border-variant="info"
        v-model="showSelectedOrderInfoModal"
        :hide-footer="true"
        id="selected-order-info-modal"
      >
        <b-container v-if="selectedOrder">
          <div class="row">
            <div class="col-6">
              <span class="font-weight-bold">Documento: </span>{{selectedOrder.number ? selectedOrder.number : 'Sem documento'}}
            </div>
            <div class="col-6">
              <span class="font-weight-bold">Operação: </span>
              <span :class="'badge ' + getOrderShowTypeBadgeColor(selectedOrder.showType)">{{selectedOrder.showType}}</span>
            </div>
            <div class="col-12 mt-3">
              <div v-if="selectedOrder.fiscalOperation">
                <span class="font-weight-bold">
                  {{selectedOrder.fiscalOperation.type === 'ENT' ? 'Origem' : selectedOrder.fiscalOperation.type === 'SAI' ? 'Destino' : ''}}:
                </span>
                {{selectedOrder.customer && selectedOrder.customer.person ? selectedOrder.customer.person.name : ''}}
              </div>
            </div>
            <div class="col-12 mt-3">
              <span class="font-weight-bold">Armazém: </span>{{selectedOrder.stockLocale ? selectedOrder.stockLocale.name : ''}}
            </div>
            <div class="col-12 mt-3">
              <span class="font-weight-bold">Iniciado em: </span>{{selectedOrder.formattedStartDate}}
            </div>
            <div class="col-12 mt-3">
              <span class="font-weight-bold">Finalizado em: </span>{{selectedOrder.formattedEndDate}}
            </div>
          </div>
        </b-container>
      </b-modal>
    </div>
  </div>
</template>

<script>
import { ModelListSelect } from 'vue-search-select'
import { LoadingAnimation } from '@/components/loaders'
import shared from '@/shared/shared'
import { httpClient } from '@/service'
import ButtonBack from '@/components/forms/c-button-back'
import {AgGridVue} from "@ag-grid-community/vue";
import { AllModules } from '@ag-grid-enterprise/all-modules';

import '@ag-grid-community/core/dist/styles/ag-grid.css';
import '@ag-grid-community/core/dist/styles/ag-theme-balham.css';

export default {
  name: 'order-track',
  components: { ModelListSelect, LoadingAnimation, ButtonBack, 'ag-grid-vue': AgGridVue },

  data () {
    return {
      item: {},
      direction: 'DESC',
      items: [],
      loadingOrder: false,
      order: null,
      items: [],
      loadingTrack: false,
      columnDefs: null,
      rowData: null,
      nodes: [],
      agGridModules: AllModules,
      groupDefaultExpanded: -1,
      autoGroupColumnDef: {
        field: 'hierarchyLevel',
        headerName: 'Hierarquia',
        resizable: true,
        cellRendererParams: {
          suppressCount: true
        }
      },
      gridOptions: {
        onRowDoubleClicked: this.onRowDoubleClicked
      },
      defaultColDef: { flex: 1 },
      gridColumnApi: null,
      gridApi: null,
      searchButtonAtlLeastOncePressed: false,
      showSelectedOrderInfoModal: false,
      selectedOrder: null
    }
  },

  mounted() {
    this.$store.commit('app/SET_CURRENT_PAGE', { title:'Rastreio', size: 3})
    this.direction = this.$route.query.direction;
    this.findOrder();

    this.columnDefs = [
      //{ field: 'id', headerName: 'ID', resizable: true },
      { field: 'type', headerName: 'Tipo do movimento', resizable: true, cellRenderer: this.itemLotMovementTypeCellRenderer },
      { field: 'orderItem.order.number', headerName: 'Número do pedido', resizable: true },
      { field: 'itemLot.lotNumber', headerName: 'Número do lote', resizable: true },
      { field: 'orderItem.order.showType', headerName: 'Operação do pedido', resizable: true, cellRenderer: this.orderShowTypeCellRenderer },
      { field: 'quantity', headerName: 'Quantidade', resizable: true },
      { field: 'location.name', headerName: 'Endereço', resizable: true },
      { field: 'formattedDate', headerName: 'Data do movimento', resizable: true },
      { field: 'orderItem.item.label', headerName: 'Item', resizable: true }
    ];
  },

  methods: {
    setItemsLabel() {
      if (this.items && this.items.length) {
        this.items.forEach(item => {
          if (item.id) {
            item.label = this.getItemReduzidoFromRef(item.ref) + " - " + item.description;
          }
        });
      }
    },

    getItemReduzidoFromRef(ref) {
      return shared.getItemReduzidoFromRef(ref);
    },

    findOrder() {
      this.loadingOrder = true;
      httpClient.get(`${process.env.VUE_APP_API_URL}orders/${this.$route.params.id}`)
      .then(data => {
        this.loadingOrder = false;
        this.order = data.data.data;
        this.items = this.order.orderItems.map(oi => {
          return oi.item;
        });
        this.setItemsLabel();
      }).catch(error => {
        this.loadingOrder = false;
        if (error.message) {
          this.$notify.error(error)
        } else {
          this.$notify.textError("Houve um erro ao buscar o pedido");
        }
      });
    },

    searchHierarchy() {
      this.searchButtonAtlLeastOncePressed = true;
      this.nodes = [];
      this.rowData = [];

      this.getOrderHierarchy(this.order);
    },

    getOrderHierarchy(order) {
      this.loadingTrack = true;
      httpClient.get(`${process.env.VUE_APP_API_URL}orders/hierarchy/${order.id}/item/${this.item.id}/direction/${this.direction}`)
      .then(data => {
        this.loadingTrack = false;
        let newNodes = this.setItemLabelsAndFormatCreatedAt(data.data.data);
        this.nodes.push(...newNodes);
        this.mountGridRowData(newNodes);
      }).catch(error => {
        this.loadingTrack = false;
        if (error.message) {
          this.$notify.error(error)
        } else {
          this.$notify.textError("Houve um erro ao rastrear o pedido");
        }
      });
    },

    orderShowTypeCellRenderer(params) {
      if (!params || !params.value) {
        return '';
      }

      let color = this.getOrderShowTypeBadgeColor(params.value);

      if (color) {
        return '<span class="badge ' + color + '">' + params.value +'</span>';
      } else {
        return '';
      }
    },

    getOrderShowTypeBadgeColor(showType) {
      let color = null;

      switch (showType) {
        case 'ENT':
        case 'SAI': color = 'badge-primary'; break;
        case 'PRODUCAO': color = 'badge-warning'; break;
        case 'TRANSFORMACAO': color = 'badge-success'; break;
        case 'TRANSFERENCIA': color = 'orange-background'; break;
        case 'AJU': color = 'badge-danger'; break;
      }

      return color;
    },

    itemLotMovementTypeCellRenderer(params) {
      if (!params || !params.value) {
        return '';
      }

      let color = null;

      switch (params.value) {
        case 'ENT': color = 'badge-danger'; break;
        case 'SAI': color = 'badge-primary'; break;
      }

      if (color) {
        return '<span class="badge ' + color + '">' + params.value +'</span>';
      } else {
        return '';
      }
    },

    mountGridRowData(nodes) {
      this.rowData = [];

      if (nodes && nodes.length) {
        nodes.filter(n => n.orderItem && n.orderItem.order && n.orderItem.order.id === this.order.id).forEach(n => {
          this.addRowDataNode(n);
        });
      }

      this.autoSizeAll(false);
    },

    addRowDataNode(n, parentIds) {
      n = JSON.parse(JSON.stringify(n));
      n.idHierarchy = [];

      if (parentIds) {
        n.idHierarchy.push(...parentIds);
      }

      n.idHierarchy.push(n.id);
      n.hierarchyLevel = n.idHierarchy.length;

      if (n.children && n.children.length) {
        n.children.forEach(cId => {
          let index = this.nodes.findIndex(n2 => n2.id === cId);

          if (index > -1) {
            this.addRowDataNode(this.nodes[index], n.idHierarchy);
          }
        });
      }

      this.rowData.push(n);
    },

    getDataPath(data) {
      return data.idHierarchy;
    },

    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
    },

    autoSizeAll(skipHeader) {
      if (this.rowData && this.rowData.length) {
        setTimeout(() => {
          if (this.gridColumnApi) {
            var allColumnIds = ['ag-Grid-AutoColumn'];
            this.gridColumnApi.getAllColumns().forEach(function(column) {
              allColumnIds.push(column.colId);
            });
            this.gridColumnApi.autoSizeColumns(allColumnIds, skipHeader);
          }
        }, this.rowData.length * 10);
      }
    },

    setItemLabelsAndFormatCreatedAt(nodes) {
      if (nodes && nodes.length) {
        nodes.forEach(n => {
          if (n.orderItem) {
            if (n.orderItem.item) {
              n.orderItem.item.label = this.getItemReduzidoFromRef(n.orderItem.item.ref) + ' - ' + n.orderItem.item.description;
            }

            if (n.orderItem.order) {
              n.orderItem.order.formattedStartDate = shared.formatDate(n.orderItem.order.allocationStartDate);
              n.orderItem.order.formattedEndDate = shared.formatDate(n.orderItem.order.allocationEndDate);
            }
          }

          if (n.createdAt) {
            n.formattedDate = shared.formatDate(n.createdAt);
          }
        });
      }

      return nodes;
    },

    getContextMenuItems(params) {
      let items = [{
        name: 'Copiar',
        icon: '<i class="fa fa-copy"></i>',
        action: () => {
          shared.copyText(params.value).then(() => {
            this.$notify.success('Copiado');
          }).catch(error => {
            console.error(error);
            this.$notify.success('Houve um erro ao copiar');
          });
        }
      }];

      if (params.node && params.node.data && params.node.data.orderItem && params.node.data.orderItem.order) {
        items.push({
          name: 'Abrir informações do pedido ' + (params.node.data.orderItem.order.number || params.node.data.orderItem.order.documentNumber),
          icon: '<i class="fa fa-info-circle"></i>',
          action: () => {
            this.openOrderInfoModal(params.node.data.orderItem.order);
          }
        });
        items.push({
          name: 'Rastrear pedido ' + params.node.data.orderItem.order.number + ' em uma nova aba',
          action: () => {
            let direction = 'DESC';

            if (params.node.data.orderItem.order.fiscalOperation && params.node.data.orderItem.order.fiscalOperation.type === 'ENT') {
              direction = 'ASC';
            }

            window.open(window.origin + '/#/orders/track/' + params.node.data.orderItem.order.id + '?direction=' + direction, '_blank');
          }
        });
      }

      return items;
    },

    openOrderInfoModal(order) {
      this.selectedOrder = order;
      this.showSelectedOrderInfoModal = true;
    },
  }
}
</script>
